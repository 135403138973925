<template>
  <router-view/>
</template>

<style>
  html{
    overflow: hidden;
    overflow-y: auto;
  }
</style>
